import React from "react"
import Layout from "./../components/layout/Main"
import BlogRoll from "./../components/BlogRoll"
import { Heading } from "../components/styled/BlogRollHeading"
import { graphql, Link } from "gatsby"
import { BackLink } from "../components/styled/StyledLink"

export const CategoriesTemplate = props => {
  const { data } = props
  const posts = data?.posts.edges
  const category = data?.category

  return (
    <Layout pageContext={props.pageContext}>
      <div>
        <Heading>Izabrana kategorija: “{category?.frontmatter.title}“</Heading>
        <BackLink to="/">x</BackLink>
        <BlogRoll posts={posts} />
      </div>
    </Layout>
  )
}

export default CategoriesTemplate

export const pageQuery = graphql`
  query CategoriesPage($category: String) {
    posts: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { category: { eq: $category }, draft: { ne: true } }
      }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            category
            description
            date(formatString: "DD-MMM-YYYY")
            thumbnail_image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    category: markdownRemark(
      frontmatter: {
        templateKey: { eq: "categories-page" }
        slug: { eq: $category }
      }
    ) {
      id
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
